import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdLocalFlorist } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"modality"} />
		<Helmet>
			<title>
				Venta-Trans Europe - Freight Forwarding Company
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Components.Header />
		<Section background="--secondaryGradient" padding="64px 0" sm-padding="40px 0" color="--green">
			<Stack>
				<StackItem width="50%" lg-width="100%">
					<Override
						slot="StackItemContent"
						color="--dark"
						padding="98px 64px"
						background="--color-light"
						flex-direction="column"
					/>
					<Text
						letter-spacing="1px"
						text-transform="uppercase"
						as="h4"
						margin="12px 0"
						font="--base"
						color="--text2"
					>
						eco
					</Text>
					<Text
						as="h2"
						margin="12px 0"
						font="--headline2"
						md-font="--headline3"
						color="--green"
					>
						Environmental Responsibility
					</Text>
					<Text font="--base">
						<Strong>
							Our Partners
						</Strong>
					</Text>
					<Text color="--text2" font="--base">
						We audit our partners to ensure they apply our strong values around environmental responsibility by reducing their carbon footprint as far as possible,  eg investing in Euro 6 and 6D tractors and lower emission vehicles.
					</Text>
					<Text color="--text" font="--base">
						<Strong>
							Low carbon developments
						</Strong>
					</Text>
					<Box
						padding="0 0 0 54px"
						sm-padding="54px 0 0 0"
						position="relative"
						max-width="800px"
						margin="5px 0 0 0"
					>
						<Icon
							left="0"
							size="36px"
							color="--green"
							category="md"
							icon={MdLocalFlorist}
							position="absolute"
							top="0"
						/>
						<Text as="p" font="--base" margin="10px 0" color="--greyD2">
							New intermodal services.{" "}
						</Text>
					</Box>
					<Box
						sm-padding="54px 0 0 0"
						position="relative"
						max-width="800px"
						margin="5px 0 0 0"
						padding="0 0 0 54px"
					>
						<Icon
							top="0"
							left="0"
							size="36px"
							color="--green"
							category="md"
							icon={MdLocalFlorist}
							position="absolute"
						/>
						<Text as="p" font="--base" margin="10px 0" color="--greyD2">
							Euro 6 is now our standard for carriers.{" "}
						</Text>
					</Box>
					<Box
						padding="0 0 0 54px"
						sm-padding="54px 0 0 0"
						position="relative"
						max-width="800px"
						margin="5px 0 0 0"
					>
						<Icon
							icon={MdLocalFlorist}
							position="absolute"
							top="0"
							left="0"
							size="36px"
							color="--green"
							category="md"
						/>
						<Text font="--base" margin="10px 0" color="--greyD2" as="p">
							Euro 6D to be used where possible from 2021.
						</Text>
					</Box>
					<Box
						margin="5px 0 0 0"
						padding="0 0 0 54px"
						sm-padding="54px 0 0 0"
						position="relative"
						max-width="800px"
					>
						<Icon
							size="36px"
							color="--green"
							category="md"
							icon={MdLocalFlorist}
							position="absolute"
							top="0"
							left="0"
						/>
						<Text font="--base" margin="10px 0" color="--greyD2" as="p">
							We use low energy and low waste office space with recycling where possible.
						</Text>
					</Box>
				</StackItem>
				<StackItem width="50%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Stack>
						<StackItem lg-width="33.3%" md-width="100%" width="100%">
							<Override slot="StackItemContent" padding-bottom="100%" background="url(https://images.unsplash.com/photo-1468272687535-bac12b03f098?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover" />
						</StackItem>
						<StackItem md-width="50%" sm-width="100%" width="50%" lg-width="33.3%">
							<Override slot="StackItemContent" padding-bottom="100%" background="url(https://images.unsplash.com/photo-1544611910-3cb8d15aa90e?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover" />
						</StackItem>
						<StackItem width="50%" lg-width="33.3%" md-width="50%" sm-width="100%">
							<Override slot="StackItemContent" padding-bottom="100%" background="url(https://images.unsplash.com/photo-1575999502951-4ab25b5ca889?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1568&q=80) center/cover" />
						</StackItem>
					</Stack>
				</StackItem>
			</Stack>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="300px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Text
				letter-spacing="1px"
				max-width="850px"
				color="--text2"
				margin="0px"
				font="--lead"
				text-transform="uppercase"
			>
				Safety
			</Text>
			<Text
				margin="8px 0px 32px"
				font="--headline2"
				md-font="--headline2"
				color="--green"
				max-width="850px"
				as="h1"
			>
				Environmental Safety in Our Operations
			</Text>
			<Stack font="--base" margin-top="auto" color="--grey">
				<StackItem width="25%" md-width="50%" sm-width="100%">
					<Text margin="0px" font="--base" color="--text2">
						Ensuring environmental safety in the transport of hazardous substances
					</Text>
				</StackItem>
				<StackItem width="25%" md-width="50%" sm-width="100%">
					<Text margin="0px" color="--text2" font="--base">
						We ship waste and hazardous substances for our customers. We audit the carrier to be sure they meet EU laws and directives for hazardous shipping.
					</Text>
				</StackItem>
				<StackItem sm-width="100%" width="25%" md-width="50%">
					<Text color="--text2" font="--base" margin="0px">
						Loads are photographed prior to departure to ensure safe lashing and fixing (Rotterdam)
					</Text>
				</StackItem>
				<StackItem width="25%" md-width="50%" sm-width="100%">
					<Text margin="0px" color="--text2" font="--base">
						We actively monitor consumables and energy consumption seeking reduced electrical and water consumption and recycling where possible.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});